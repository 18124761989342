import React, { Component } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import PageHeader from '../../components/PageHeader';
import SectionLeftRight from '../../components/SectionLeftRight';

class WhiteLabelWebsitesPage extends Component {
  render() {
    const { data } = this.props;
    return (
      <Layout>
        <SEO title="White Label Websites" />
        <PageHeader
          heading="White Label Websites"
          subHeading="Our experienced team of developers will help you achieve the results your clients need, assisting in the entire development process - from identifying and specifying your clients requirements, through to testing and providing CMS training."
          buttonText="Request a Quote"
          buttonLink="/contact/"
        />

        <SectionLeftRight
          image={data.contentMarketing.childImageSharp.gatsbyImageData}
          heading="Offer more value to your customers"
          content="Deliver additional services to your customers without additional investments in hiring new staff, training, or equipment by using our white label website development service. We have extensive experience in providing white-label websites for marketing and design agencies. We deliver bespoke development that is designed to provide long-term value to you and your clients. We will ensure that your clients are provided with a website that delivers a superb return on investment."
          flip
        />
      </Layout>
    );
  }
}

export default WhiteLabelWebsitesPage;

export const query = graphql`
  query {
    contentMarketing: file(
      relativePath: { eq: "content-marketing_202307290.png" }
    ) {
      childImageSharp {
        gatsbyImageData(placeholder: BLURRED)
      }
    }
  }
`;
